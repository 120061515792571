/**
 ** Default theme colors
 ** color-500 = base color
 ** opacity is depending on background color
 **/
:root {
  // Basic colors
  --primary-100: #66899d;
  --primary-200: #4c758c;
  --primary-300: #33627c;
  --primary-400: #194e6c;
  --primary-500: #003b5c;
  --primary-600: #00304d;
  --primary-700: #092d41;
  --primary-800: #122a42;
  --primary-900: #0d1422;
  --primary-opacity-100: #e2e7eb;
  --primary-opacity-300: #afc1cb;
  --primary-opacity-500: #7e9bab;
  --primary-opacity-700: #4b738c;
  --primary-opacity-900: #194e6d;

  --secondary-200: #ffddba;
  --secondary-400: #ff9830;
  --secondary-500: #ea7600;
  --secondary-600: #af5800;
  --secondary-800: #3a1d00;
  --secondary-opacity-100: #f9ede1;
  --secondary-opacity-300: #f5d3ae;
  --secondary-opacity-500: #f3b87e;
  --secondary-opacity-700: #ef9e4c;
  --secondary-opacity-900: #ec8318;

  --warning-200: #ffdfbf;
  --warning-400: #ffa040;
  --warning-500: #ff8000;
  --warning-600: #bf6000;
  --warning-800: #402000;
  --warning-opacity-100: #fbede0;
  --warning-opacity-300: #fcd6af;
  --warning-opacity-500: #fdbd7d;
  --warning-opacity-700: #fea54b;
  --warning-opacity-900: #ff8c19;
  --warning-background: #fff3cd;

  --info-200: #bad7ff;
  --info-400: #3087fe;
  --info-500: #0162e8;
  --info-600: #0149ae;
  --info-800: #00183a;
  --info-opacity-100: #e2ebfa;
  --info-opacity-300: #b0cdf5;
  --info-opacity-500: #7eadf1;
  --info-opacity-700: #4b90ed;
  --info-opacity-900: #1b71ea;

  --danger-200: #ffc2ca;
  --danger-400: #ff4861;
  --danger-500: #ff0b2c;
  --danger-600: #c7001b;
  --danger-800: #420009;
  --danger-opacity-100: #fbe2e5;
  --danger-opacity-300: #fbb2bd;
  --danger-opacity-500: #fd8392;
  --danger-opacity-700: #ff536b;
  --danger-opacity-900: #ff2241;

  --success-200: #c5f6d5;
  --success-400: #51e482;
  --success-500: #21d15c;
  --success-600: #199d45;
  --success-800: #083417;
  --success-opacity-100: #e4f6ea;
  --success-opacity-300: #b8eeca;
  --success-opacity-500: #8ee6aa;
  --success-opacity-700: #63de8d;
  --success-opacity-900: #36d66c;

  --tertiary-200: #dcdfe7;
  --tertiary-400: #969fb6;
  --tertiary-500: #737f9e;
  --tertiary-600: #545e79;
  --tertiary-800: #1c1f28;
  --tertiary-opacity-100: #edeef2;
  --tertiary-opacity-300: #d2d4e0;
  --tertiary-opacity-500: #b7bdcd;
  --tertiary-opacity-700: #9ca4b9;
  --tertiary-opacity-900: #818ca8;

  // Specific colors
  --background: #fafafa;
  --background-transparent: rgba(255, 255, 255, 0.8);
  --foreground: #ecf0fa;
  --table-odd: #fafafa;
  --table-even: #e5ecf1;
  --table-hover: #fcbe8092;
  --border: #7892a0;
  --secondary-action: #cccccc;

  --disabled: #81878b;
  --disabled-background: #cfcfcf;

  --text: #fafafa;
  --text-d: #20282d;
  --text-l2d: #fafafa;
  --text-d2l: #20282d;

  --input-base: #5e778f;
  --selection-hover: rgba(0, 0, 0, 0.08);

  // Font family
  --ff-cotral: 'Inter', arial, sans-serif;

  --font-h1: 200 2.1rem/2.5rem var(--ff-cotral);
  --font-h2: 200 2.25rem/2.75rem var(--ff-cotral);
  --font-h3: 200 1.3rem/1.5rem var(--ff-cotral);
  --font-h4: 300 1.2rem/1.4rem var(--ff-cotral);
  --font-h5: 300 1rem/1.3rem var(--ff-cotral);

  --font-p: 330 0.9375rem/1.5rem var(--ff-cotral);
}

/**
 ** Dark theme colors
 ** color-500 = base color
 ** opacity is depending on background color
 **/
body.dark-theme {
  // Basic colors
  --primary-100: #667a84;
  --primary-200: #4c6470;
  --primary-300: #334e5b;
  --primary-400: #193847;
  --primary-500: #002233;
  --primary-600: #00080c;
  --primary-700: #001b28;
  --primary-800: #001723;
  --primary-900: #00141e;
  --primary-opacity-100: #24292f;
  --primary-opacity-300: #1c252e;
  --primary-opacity-500: #14212a;
  --primary-opacity-700: #041a27;
  --primary-opacity-900: #0c1e28;

  --secondary-200: #ffddba;
  --secondary-400: #ff9830;
  --secondary-500: #ea7600;
  --secondary-600: #af5800;
  --secondary-800: #3a1d00;
  --secondary-opacity-100: #3c332a;
  --secondary-opacity-300: #634221;
  --secondary-opacity-500: #895118;
  --secondary-opacity-700: #b1600f;
  --secondary-opacity-900: #d76e05;

  --warning-200: #ffdfbf;
  --warning-400: #ffa040;
  --warning-500: #ff8000;
  --warning-600: #bf6000;
  --warning-800: #402000;
  --warning-opacity-100: #3e342b;
  --warning-opacity-300: #694521;
  --warning-opacity-500: #935518;
  --warning-opacity-700: #be670e;
  --warning-opacity-900: #ea7605;
  --warning-background: #fff3cd;

  --info-200: #bfe8ff;
  --info-400: #40b9ff;
  --info-500: #00a2ff;
  --info-600: #0079bf;
  --info-800: #002840;
  --info-opacity-100: #253846;
  --info-opacity-300: #1c4e6f;
  --info-opacity-500: #146698;
  --info-opacity-700: #0c7fc0;
  --info-opacity-900: #0496eb;

  --danger-200: #ffc2ca;
  --danger-400: #ff4861;
  --danger-500: #ff0b2c;
  --danger-600: #c7001b;
  --danger-800: #420009;
  --danger-opacity-100: #3d282f;
  --danger-opacity-300: #69212f;
  --danger-opacity-500: #941b2e;
  --danger-opacity-700: #bf162d;
  --danger-opacity-900: #e90e2c;

  --success-200: #c5f6d5;
  --success-400: #51e482;
  --success-500: #21d15c;
  --success-600: #199d45;
  --success-800: #083417;
  --success-opacity-100: #273c35;
  --success-opacity-300: #265d3e;
  --success-opacity-500: #257e46;
  --success-opacity-700: #22a04f;
  --success-opacity-900: #21c157;

  --tertiary-200: #dcdfe7;
  --tertiary-400: #969fb6;
  --tertiary-500: #737f9e;
  --tertiary-600: #545e79;
  --tertiary-800: #1c1f28;
  --tertiary-opacity-100: #30353b;
  --tertiary-opacity-300: #3e4452;
  --tertiary-opacity-500: #4e5567;
  --tertiary-opacity-700: #5e667d;
  --tertiary-opacity-900: #6c7793;

  // Specific colors
  --background: #282b30;
  --background-transparent: rgba(0, 0, 0, 0.8);
  --foreground: #1e2124;
  --table-odd: #40444b;
  --table-even: #2f3136;
  --table-hover: #ff730048;
  --border: #6c758f;
  --secondary-action: #a5a5a5;

  --disabled: #101010;
  --disabled-background: #49505a;

  --text: #fafafa;
  --text-d: #20282d;
  --text-l2d: #20282d;
  --text-d2l: #fafafa;
  
  --input-base: #5e778f;
  --selection-hover: rgba(0, 0, 0, 0.08);
}