/**
 ** Breakpoints
 **/
$breakpoints: (
  order-list: 1450px,
  error-list: 1420px,
  catalog-list: 1040px,
  catalog-details: 1560px,
  order-history: 1260px,
  order-line-selection: 840px,
);

$button-breakpoints: (
  'xs': 576px,
  'sm': 768px,
  'md': 992px,
  'lg': 1200px,
  'xl': 1400px,
);