/**
 ** File imports
 **/
@use '@angular/material' as mat;
@use '@assets/theme/theme.scss';
@use '@assets/theme/variables.scss';
@use 'sass:map';
@include mat.core();

@font-face {
  font-family: 'Inter'; // Nom permettant d'utiliser la police dans le CSS
  src: url('/assets/fonts/InterVariable.woff2') format('woff2-variations');
  src: url('/assets/fonts/InterVariable.woff2') format('woff2') tech('variations');
  font-weight: 100 1000;
  font-stretch: 25% 151%;
}

body {
  font-family: 'Inter', arial, sans-serif;
  transition: background-color 0.5s ease, color 0.5s ease;
}

/* Si nécessaire, remplacer la police de certains éléments Bootstrap */
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span {
  font-family: 'Inter', sans-serif !important;
}

/**
 ** Page style
 **/
html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  background-color: var(--foreground);
}

/**
 ** MIXINS
 **/
@mixin no-text-interaction {
  user-select: none; /* Webkit */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  pointer-events: none;
}

// White text whether in light or dark theme
.text {
  color: var(--text);
}

.text-d {
  color: var(--text-d);
}

.text-l2d {
  color: var(--text-l2d);
}

.text-d2l {
  color: var(--text-d2l);
}

.thin {
  font-weight: 100;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.black {
  font-weight: 900;
}

section {
  background-color: var(--background);
  padding: 1rem 1rem;
  margin: 0 0.3rem;
  border-radius: 10px;
}

@media (min-width: 768px) {
  section {
    padding: 1.2rem 1.2rem;
    margin: 0 0.6rem;
  }
}

// Common mixins
@mixin base-button-styles {
  padding: 0 0.5rem;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 2.125rem;
  min-width: 2.125rem;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    overflow: hidden;
  }

  span {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
  }
}

@mixin auto-height-and-align {
  &[auto-height='true'] {
    height: auto;
    min-height: 2.125rem;
    padding: 0.375rem 0.5rem;

    // Gestion de l'alignement
    &[text-align='left'] {
      justify-content: flex-start;
      text-align: left;
    }

    &[text-align='right'] {
      justify-content: flex-end;
      text-align: right;
    }

    &[text-align='center'] {
      justify-content: center;
      text-align: center;
    }

    // Ajustement de l'alignement avec les icônes
    &[icon='before'][text-align='left'] {
      app-icon {
        margin-right: 0.4em;
      }
    }

    &[icon='after'][text-align='right'] {
      app-icon {
        margin-left: 0.4em;
      }
    }
  }

  &:not([auto-height='true']) {
    justify-content: center;
  }
}

@mixin icon-positioning {
  &[icon='before'] {
    app-icon {
      margin-right: 0.4em;
      order: -1;
    }
  }

  &[icon='after'] {
    app-icon {
      margin-left: 0.4em;
      order: 1;
    }
  }
}

@mixin rounded-style {
  &[rounded='true'] {
    border-radius: 16px;

    &:has(app-icon:only-child) {
      aspect-ratio: 1/1;
      border-radius: 50%;
    }
  }
}

@mixin url-style {
  &[url='true'] {
    text-decoration: underline;
  }
}

@mixin button-active {
  &:not([tag='true']) {
    position: relative;

    &:active::before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: rgba(255, 255, 255, 0.2);
      pointer-events: none;
    }
  }
}

@mixin responsive-mode {
  &[mode='responsive'] {
    // Utilisation d'un breakpoint prédéfini si spécifié
    @each $name, $width in variables.$button-breakpoints {
      &[responsive-breakpoint='#{$name}'] {
        @media screen and (max-width: #{$width}) {
          span:not(:only-child) {
            display: none;
          }

          &[icon='before'],
          &[icon='after'] {
            app-icon {
              margin: 0;
            }
          }
        }
      }
    }

    // Fallback sur le breakpoint par défaut si aucun n'est spécifié
    &:not([responsive-breakpoint]) {
      @media screen and (max-width: map.get(variables.$button-breakpoints, 'sm')) {
        span:not(:only-child) {
          display: none;
        }

        &[icon='before'],
        &[icon='after'] {
          app-icon {
            margin: 0;
          }
        }
      }
    }
  }
}

@mixin button-sizes {
  &[size='small'] {
    height: 1.75rem;
    min-width: 1.75rem;
    padding: 0 0.375rem;
    font-size: 0.875rem;
    border-radius: 6px;

    app-icon {
      font-size: 0.875rem;
    }
  }

  &[size='large'] {
    height: 2.5rem;
    min-width: 2.5rem;
    padding: 0 0.625rem;
    font-size: 1.125rem;
    border-radius: 8px;

    app-icon {
      font-size: 1.125rem;
    }
  }
}

// Button Types
$buttonTypes: primary, secondary, tertiary, info, success, warning, danger;

/**
 ** Button full
 ** Usage : class="button-full-<type>"
 ** Options:
 ** - icon="before | after" - default none
 ** - rounded="true" - default false (squared)
 ** - url="true" - default false
 **/
.button-full {
  @each $type in $buttonTypes {
    &-#{$type} {
      @include base-button-styles;

      // Type-specific styles
      background-color: var(--#{$type}-500);
      color: var(--text);
      border: 1px solid var(--#{$type}-500);

      &:not([tag='true']):hover {
        background-color: var(--#{$type}-600);
        color: var(--text);
      }

      &[tag='true'] {
        cursor: default;
        padding: 0 0.75rem;
      }

      @include icon-positioning;
      @include rounded-style;
      @include url-style;
      @include button-active;
      @include responsive-mode;
      @include button-sizes;
      @include auto-height-and-align;
    }
  }
}

/**
 ** Button part
 ** Usage : class="button-part-<type>"
 ** Options: same as button-full
 **/
.button-part {
  @each $type in $buttonTypes {
    &-#{$type} {
      @include base-button-styles;

      // Type-specific styles
      background-color: transparent;
      color: var(--#{$type}-500);
      border: 1px solid var(--#{$type}-500);

      &:hover {
        background-color: var(--#{$type}-200);
      }

      @include icon-positioning;
      @include rounded-style;
      @include url-style;
      @include button-active;
      @include responsive-mode;
      @include button-sizes;
      @include auto-height-and-align;
    }
  }
}

/**
 ** Button basic
 ** Usage : class="button-basic-<type>"
 ** Options: same as button-full
 **/
.button-basic {
  @each $type in $buttonTypes {
    &-#{$type} {
      @include base-button-styles;

      // Type-specific styles
      background-color: var(--#{$type}-opacity-100);
      color: var(--#{$type}-500);
      border: none;

      &:hover {
        background-color: var(--#{$type}-opacity-300);
      }

      @include icon-positioning;
      @include rounded-style;
      @include url-style;
      @include button-active;
      @include responsive-mode;
      @include button-sizes;
      @include auto-height-and-align;

      // Additional basic-specific styles
      &[background='none'] {
        background-color: transparent;

        &:hover {
          background-color: var(--#{$type}-opacity-100);
        }
      }

      &[line='true'] {
        &:hover {
          background-color: var(--secondary-opacity-100);
        }
      }
    }
  }
}

.button-text {
  @each $type in $buttonTypes {
    &-#{$type} {
      @include base-button-styles;

      // Type-specific styles
      background-color: transparent;
      color: var(--#{$type}-500);
      border: none;

      &:hover {
        color: var(--secondary-500);
      }

      @include icon-positioning;
      @include rounded-style;
      @include url-style;
      @include button-active;
      @include responsive-mode;
      @include button-sizes;
      @include auto-height-and-align;
    }
  }
}

.bttn-icon {
  $buttonTypes: (
    primary: (
      color: var(--primary-500),
      hover: var(--secondary-500),
      underline: false,
    ),
    info: (
      color: var(--info-500),
      hover: var(--secondary-500),
      underline: false,
    ),
    url: (
      color: var(--info-500),
      hover: var(--secondary-500),
      underline: true,
    ),
    success: (
      color: var(--success-500),
      hover: var(--secondary-500),
      underline: false,
    ),
    warning: (
      color: var(--secondary-500),
      hover: var(--text-d),
      underline: false,
    ),
    danger: (
      color: var(--danger-500),
      hover: var(--secondary-500),
      underline: false,
    ),
    tertiary: (
      color: var(--tertiary-500),
      hover: var(--secondary-500),
      underline: false,
    ),
  );

  @each $type, $styles in $buttonTypes {
    &-#{$type} {
      color: map.get($styles, color);
      text-decoration: none;
      background-color: transparent;
      border: none;
      cursor: pointer;

      @if map.get($styles, underline) == true {
        text-decoration: underline;
        text-decoration-color: map.get($styles, color);
      }

      i {
        font-size: 24px;
        line-height: 34px;
      }

      &:hover {
        color: map.get($styles, hover);
        @if map.get($styles, underline) == true {
          text-decoration-color: map.get($styles, hover);
        }
      }

      &-disabled {
        color: var(--disabled);
        &:hover {
          color: var(--disabled);
        }
      }
    }
  }
}

.bttn-tag {
  display: inline-flex;
  color: var(--text);
  background: var(--info-500);
  border-radius: 6px;
  padding: 2px 6px;
  border: none;
  box-sizing: content-box;
  width: auto;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
}

.bttn {
  $buttonTypes: primary, info, secondary, url, warning, success, danger, tertiary;

  @each $type in $buttonTypes {
    &-#{$type} {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0.25rem 0.5rem;
      color: var(--text);
      border-radius: 6px;
      border: none;
      background-color: var(--#{$type}-500);
      box-sizing: content-box;
      width: auto;
      height: auto;

      &:hover {
        background-color: var(--#{$type}-600);
      }

      &:active {
        background-color: var(--#{$type}-400);
      }

      &-before {
        @extend .bttn-#{$type};
        app-icon {
          margin-right: 0.4em;
        }
      }

      &-after {
        @extend .bttn-#{$type};
        app-icon {
          margin-left: 0.4em;
        }
      }
    }
  }

  @each $type in $buttonTypes {
    &-#{$type}-small {
      @extend .bttn-#{$type};
      padding: 2px 8px;
      border-radius: 4px;
      background-color: var(--background);
      border: 1px solid var(--#{$type}-500);
      color: var(--#{$type}-500);

      &:hover {
        background-color: var(--#{$type}-500);
        color: var(--background);
      }

      &:active {
        background-color: var(--#{$type}-400);
        color: var(--background);
      }
    }
  }
}

.linkable {
  color: var(--info-500);

  &:hover {
    color: var(--secondary-500);
  }

  &:active {
    color: var(--secondary-400);
  }
}

.fieldset {
  border: 1px solid var(--primary-500);
  border-radius: 10px;
  padding: 1rem;
  background-color: transparent;
}

/**
 ** Tables
 **/

.tbl-container {
  overflow: hidden;
  background: inherit;
  overflow-y: auto;
  overflow-x: auto;
  // max-height: 30em;
  max-height: 60em; //just for test JD

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;

    &-thumb {
      background: var(--info-500);
      border-radius: 2px;
    }
  }
}

.tbl {
  width: 100%;
  border-collapse: collapse;

  thead th {
    background-color: var(--primary-500);
    color: var(--text);
    position: sticky;
    top: 0;
    z-index: 2;

    &:first-child {
      border-top-left-radius: 10px;
      box-shadow: -3px -3px 0 0 var(--background);
    }

    &:last-child {
      border-top-right-radius: 10px;
      box-shadow: 3px -3px 0 0 var(--background);
    }
  }

  th {
    padding: 0.6em;
  }

  td {
    padding: 0.8rem 0.6rem;
    text-align: left;
    border-bottom: 1px solid var(--border);
  }

  tbody tr {
    &:nth-child(odd) {
      background-color: var(--table-odd);
    }

    &:nth-child(even) {
      background-color: var(--table-even);
    }

    &:hover {
      background-color: var(--table-hover);
      font-weight: 450;
    }
  }
}

/**
 ** Separators
 **/
.separator-vr {
  height: 100%;
  width: 3px;
  background-color: var(--foreground);
}

.separator-hr {
  width: 100%;
  height: 3px;
  background-color: var(--foreground);
}

/**
 ** Card
 **/

/*** REUSABLES ***/

/**
 ** Scaling and sizing
 **/
.w100 {
  width: 100%;
}
.mxW12 {
  max-width: 12em;
}
.mxWCt {
  max-width: max-content;
}
.h100 {
  height: 100%;
}

/**
 ** Divider
 **/
.line-divider {
  height: 1em;
}

.line-divider-2 {
  height: 2em;
}

.line-divider-3 {
  height: 3em;
}

.line-divider-4 {
  height: 4em;
}

/**
** FONTS
**/
.uppercased {
  text-transform: uppercase;
}
.faded {
  opacity: 0.7;
}
/**
 ** Visibility & enabling
 **/
.hidden {
  display: none;
}

.disabled {
  background-color: var(--disabled);
}

/**
 ** Borders & shapes
 **/
.bordered {
  border: 0.3mm solid var(--border);
  border-radius: 7px;
}

.unbordered {
  border: none;
}

.rounded {
  border-radius: 7px;
}

/**
 ** Positioning
 **/
.centered {
  text-align: center;
}

.img-centered {
  text-align: center;
  margin: auto;
  display: flex;
}

.left {
  float: left;
}

.right {
  float: right;
}

.right-align {
  text-align: right;
}
.prevent-strech {
  align-items: flex-start;
}

/**
 ** Font sizes
 **/
.xxs {
  font-size: xx-small;
}

.xs {
  font-size: x-small;
}

.s {
  font-size: small;
}
.m {
  font-size: medium;
}
.l {
  font-size: large;
}
.xl {
  font-size: x-large;
}
.xxl {
  font-size: xx-large;
}

/*** REUSABLE END ***/

/*** MEDIA QUERIES ***/

/* For mobile phones: */
@media only screen and (max-width: 600px) {
  [class*='col-'] {
    width: 100%;
  }
}

// @media only screen and (min-width: 600px) {
@media only screen and (min-width: 686px) {
  /* For tablets: */
  .col-s-1 {
    width: 8.33%;
  }

  .col-s-2 {
    width: 16.66%;
  }

  .col-s-3 {
    width: 25%;
  }

  .col-s-4 {
    width: 33.33%;
  }

  .col-s-5 {
    width: 41.66%;
  }

  .col-s-6 {
    width: 50%;
  }

  .col-s-7 {
    width: 58.33%;
  }

  .col-s-8 {
    width: 66.66%;
  }

  .col-s-9 {
    width: 75%;
  }

  .col-s-10 {
    width: 83.33%;
  }

  .col-s-11 {
    width: 91.66%;
  }

  .col-s-12 {
    width: 100%;
  }
}

.toast-container {
  position: fixed;
  top: 1px;
  right: 700px;
  z-index: 1050;
}

/**
 ** Inputs
 **/
.p-inputtext {
  padding: 0.2rem 0.3rem;
}

.input-30 .p-inputtext {
  width: 1.875rem;
}

.input-40 .p-inputtext {
  width: 2.5rem;
}

.input-50 .p-inputtext {
  width: 3.125rem;
}

.input-60 .p-inputtext {
  width: 3.75rem;
}

.input-70 .p-inputtext {
  width: 4.375rem;
}

.input-80 .p-inputtext {
  width: 5rem;
}

.input-90 .p-inputtext {
  width: 5.625rem;
}

.input-100 .p-inputtext {
  width: 6.25rem;
}

.input-110 .p-inputtext {
  width: 6.875rem;
}

.input-120 .p-inputtext {
  width: 7.5rem;
}

.input-130 .p-inputtext {
  width: 8.125rem;
}

.input-140 .p-inputtext {
  width: 8.75rem;
}

.p-paginator .p-dropdown {
  height: auto;
}

.p-datatable .p-datatable-header {
  background-color: var(--primary-500);
  border: 0;
  border-radius: 10px 10px 0 0;
}

.p-datatable .p-datatable-thead > tr > th {
  border-bottom: 1px solid var(--primary-500);
  background-color: var(--table-even);
  color: var(--text-d2l);
}

.p-datatable .p-sortable-column svg {
  color: var(--text-d2l);
}

.p-datatable .p-column-filter-clear-button {
  color: var(--text-d2l);
}

.p-datatable .p-column-filter-menu-button {
  color: var(--text-d2l);
}

// Add .p-datatable if filters sidebar is reworked
.p-sortable-column.p-highlight {
  background-color: var(--selection-hover);
  color: var(--info-500);
}

.p-sortable-column:not(.p-highlight):hover {
  color: var(--info-500);

  svg {
    color: var(--info-500);
  }
}

.p-sortable-column:not(.p-highlight) svg {
  color: var(--text-d2l);
}

.p-sortable-column.p-highlight svg {
  color: var(--info-500);
}

// .p-datatable .p-datatable-thead svg:hover {
//   color: var(--info-500);
// }

// .p-dropdown-panel .p-dropdown-items .p-hidden-accessible .p-dropdown-item {
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.25rem 0.5rem;
}

.p-element .p-dropdown-item {
  white-space: normal;
  word-wrap: break-word;
}

dl,
ol,
ul {
  margin: 0;
}

ol,
ul {
  padding: 0;
}

.p-datatable-thead th {
  padding: 0.5rem 0.5rem;
}

.p-datatable .p-datatable-tbody > :not(tr.bttn-icon-danger) {
  color: var(--text-d2l);
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.3rem;
}

.p-datatable .p-datatable-tbody > tr:nth-child(odd) {
  background-color: var(--table-odd);
}

.p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: var(--table-even);
}

.p-datatable .p-datatable-tbody > tr:hover {
  background-color: var(--table-hover);
}

.p-datatable .p-datatable-tbody .table-empty {
  height: 40rem;
}

.p-datatable .p-datatable-tbody > .table-empty > td {
  text-align: center;
  vertical-align: top;
}

body .p-paginator .p-dropdown {
  transform-origin: center top;
}

.p-datatable .p-paginator {
  background-color: var(--primary-500);
  border: 0;
  border-radius: 0 0 10px 10px;

  .p-paginator-element {
    color: var(--text);
  }

  .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: var(--primary-400);
  }
}

/**
 ** Texts styling
 **/
.no-highlight {
  cursor: pointer;
  @include no-text-interaction;
}

.forbidden-text-interaction {
  cursor: not-allowed;
  @include no-text-interaction;
}

.cotral-table-responsive {
  @each $size, $breakpoint in variables.$breakpoints {
    &.mobile-breakpoint-#{$size} {
      .cotral-responsive-cell {
        .p-column-title {
          display: none;
        }
      }

      @media screen and (max-width: $breakpoint) {
        .p-datatable .p-datatable-header {
          border-bottom: 0;
          border-radius: 10px;
        }

        .p-datatable .p-paginator {
          border-radius: 10px;
        }

        .p-datatable {
          .p-datatable-tbody > tr > td {
            border: 0;
          }
        }

        .p-datatable-tbody {
          display: block;
        }

        .p-datatable-tbody > tr {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          gap: 1rem;
          margin-bottom: 0.75rem;
          border-radius: 10px;
          color: var(--text-d2l);
          border: 1px solid rgba(120, 146, 160, 0.2);
          padding: 0.25rem 0.5rem;
        }

        .p-datatable-tbody > tr:first-of-type {
          margin-top: 0.25rem;
        }

        .p-datatable-tbody > tr:last-of-type {
          margin-bottom: 0.25rem;
        }

        .p-datatable-tbody > tr > td.cotral-responsive-cell {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: start;
          padding: 0.5rem;
          margin-bottom: 0;

          &::before {
            display: none;
          }

          .p-column-title {
            margin-bottom: 0.2rem;
            font-size: 0.75rem;
            text-transform: uppercase;
            display: block;
            width: 100%;
            font-weight: 600;
            color: var(--border);
          }

          .p-column-value {
            display: flex;
            width: 100%;
            text-align: start;
          }
        }

        .fo-linked {
          margin-bottom: 0.5rem;
        }

        .p-datatable-thead {
          display: none;
        }
      }
    }
  }
}

/** Sticky **/

.sticky-toolbar {
  position: sticky;
  top: 0;
  z-index: 1000;
}

/** Dialogs / Popups / Modals **/
.p-dialog {
  background-color: var(--background);
  border-radius: 14px 14px 8px 8px;
  border: none;
}

.p-dialog .p-dialog-header {
  border-bottom: 1px solid var(--primary-500);
  color: var(--text);
  background-color: var(--primary-500);
  padding: 0.75rem;
  border-radius: 8px 8px 0 0;
}

.p-dialog .p-dialog-header .p-dialog-header-close-icon {
  color: var(--text);
}

.p-dialog .p-dialog-content {
  padding: 1rem;
  background-color: transparent;
  color: var(--text-d2l);
}

.p-dialog .p-dialog-footer {
  border-top: 1px solid var(--primary-500);
  padding: 0.5rem;
}

.tooltip-text {
  visibility: hidden;
  background-color: transparent;

  text-align: center;
  border-radius: 5px;
  padding: 5px;

  z-index: 1;

  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
}
.tooltiped-btn:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  z-index: 9000;
  // height: 5rem;
}

/*** Checkboxes ***/

.p-checkbox-box {
  border: 1px solid var(--border);
  background-color: var(--background);
}

.p-checkbox-box.p-highlight {
  border: 1px solid var(--primary-500);
  background-color: var(--primary-500);
}

.p-checkbox-box .p-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

// .p-multiselect .p-multiselect-label.p-placeholder {
//   display: none;
// }

// .p-multiselect input {
//   outline: none;
//   border: none;
//   padding: 0.25rem 0.5rem;
//   background-color: transparent;
// }

// .p-multiselect-filter-container {
//   input {
//     border: 1px solid var(--primary-500);
//     padding: 0.25rem 0.5rem;
//   }
// }

// .p-multiselect .p-multiselect-label {
//   display: none;
// }

// .p-element .p-fluid .p-multiselect {
//   padding: 0;
// }

// .p-multiselect-label-container {
//   display: none;
// }

// .p-multiselect-trigger {
//   width: auto;
// }

// div.p-hidden-accessible > input {
//   max-width: 5.6rem;
// }

// .p-checkbox .p-hidden-accessible {
//   display: none;
// }

// .p-multiselect-header .p-checkbox-box {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .p-fluid .p-multiselect {
//   display: inline-flex;
// }

/*** Column filters ***/
.p-column-filter-row {
  display: inline-flex;
  width: auto;
}

/*** Overlay ***/
// .p-overlaypanel-flipped::after,
// .p-overlaypanel-flipped::before,
// .p-overlaypanel::after,
// .p-overlaypanel::before {
//   display: none;
// }

// .p-overlaypanel {
//   background-color: var(--background);
//   border-radius: 4px;
// }

// .p-overlaypanel-content {
//   padding: 0;
// }

// .p-overlay {
//   @media (max-width: 576px) {
//     width: 100%;
//   }
// }

// .filter-sidebar {
//   .p-sidebar-header {
//     background-color: var(--background);
//     color: var(--text-d2l);

//     .p-sidebar-icon {
//       &:hover {
//         color: var(--selection-hover);
//       }
//     }
//   }

//   .p-sidebar-content {
//     background-color: var(--background);
//     color: var(--text-d2l);
//   }

//   .p-column-filter-clear-button {
//     color: var(--text-d2l);

//     &:hover {
//       color: var(--secondary-500);
//     }
//   }

//   .p-sidebar-close-icon {
//     color: var(--text-d2l);

//     &:hover {
//       color: var(--secondary-500);
//     }
//   }
// }

.p-element {
  .p-column-filter {
    .p-column-filter-menu-button {
      &:hover {
        color: var(--info-500);
        background-color: var(--selection-hover);
      }
    }
  }
}

.sidebar-filter-table.p-drawer {
  background-color: var(--background-transparent);
}
